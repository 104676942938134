import React, { useState } from "react"
import "../assets/styles/components/doc-header.scss"
import BrooiLogo from "../assets/images/new-brooi-logo.svg"
import SearchIcon from "../assets/images/search.svg"
import { Link } from "gatsby"

const IndexPage = () => {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  return (
    <>
      <header className="header-nav">
        <div className="nav-left">
          <img src={BrooiLogo} alt="" className="brooi-logo" />
        </div>
        <nav className={`nav-links ${click ? "active" : ""}`}>
          <Link to="/doc-article" className="nav-link-item" onClick={closeMobileMenu}>
            Articles
          </Link>
          <Link to="/doc-events" className="nav-link-item" onClick={closeMobileMenu}>
            Events
          </Link>
          <Link to="/doc-videos" className="nav-link-item" onClick={closeMobileMenu}>
            Videos
          </Link>
          <Link to="/doc-projects" className="nav-link-item" onClick={closeMobileMenu}>
            Projects
          </Link>
          <div className="nav-link-item">
            <img src={SearchIcon} alt="" />
          </div>
        </nav>
        <div className={`hamburger ${click ? "open" : ""}`} onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </>
  )
}

export default IndexPage
