import React from "react"
import NewHeader from "../components/docheader"
import NewFooter from "../components/docfooter"

const landingPage = () => {
  return (
    <div>
      <NewHeader />
      <NewFooter />
    </div>
  )
}

export default landingPage
