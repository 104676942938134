import React, { useState } from "react"
import mail from "../assets/images/mail.svg"
import Arrow from "../assets/images/Arrow.svg"
import "../assets/styles/components/docFooter.scss"

const DocFooter = () => {
  const [newsEmail, setNewsEmail] = useState("")

  ////////////////// handle input change ////////////////
  const handleChange = (e: any) => {
    setNewsEmail(e.target.value)
  }

  //////////////////////   handling key event ///////////////////////
  const handleInputKeyEvent = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }
  return (
    <div className="footer--container">
      <div className="footer--text">
        Keep up to date with Brooi's creative team-Sign up for our newsletter and stay informed
      </div>
      <div className="email--input--wrapper">
        <div className="email--input--inner">
          <div className="mail--image--wrapper">
            <img src={mail} alt="" />
          </div>
          <div className="main--input--wrapper">
            <input
              type="email"
              value={newsEmail}
              placeholder="example@domain.com"
              className="input--main"
              onChange={handleChange}
              onKeyUp={handleInputKeyEvent}
            />
          </div>
        </div>
        <div className="button--wrapper">
          <button className="btn--main">
            <img src={Arrow} alt="" className="arrow--image" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DocFooter
